<template>
  <div>
    <form-component :form="form" />
  </div>
</template>

<script>
import FormComponent from "./components/FormComponent.vue";

export default {
  components: {
    FormComponent,
  },
  data() {
    return {
      form: {
        company_id: "",
        title: "",
        category: "",
        vacancy: "",
        location: "",
        salary: "",
        description: "",
        field: "no need",
        last_date: "",
        short_description: "",
        duty: "",
        hours: "",
        requirements: "",
        responsibilities: "",
        benefits: "",
        skill: [
          {
            skill_id: "",
            skill_level: "",
          },
        ],
      },
      show: true,
    };
  },
  methods: {},
};
</script>

<style scoped></style>
